var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "subject-main" }, [
    _c("div", { staticClass: "subject-title" }, [
      _c("span", { staticClass: "subject-tag" }, [_vm._v("简答题")]),
      _c("span", [_vm._v(_vm._s(_vm.questionIndex + 1) + "、")]),
      _c("span", [_vm._v(_vm._s(_vm.question["question"]))]),
    ]),
    !_vm.trainning
      ? _c(
          "div",
          { staticClass: "subject-answer" },
          [
            _c("van-field", {
              attrs: {
                rows: "5",
                autosize: "",
                type: "textarea",
                maxlength: "2000",
                placeholder: "请输入答案",
                "show-word-limit": "",
              },
              on: { blur: _vm.fieldInput },
              model: {
                value: _vm.chooseKey,
                callback: function ($$v) {
                  _vm.chooseKey = $$v
                },
                expression: "chooseKey",
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm.choosed
      ? _c("div", { staticClass: "analysis-main" }, [
          _c("div", { staticClass: "analysis-content" }, [
            _c("div", { staticClass: "analysis-content-title" }, [
              _vm._v(" 题目答案 "),
            ]),
            _c("div", [_vm._v(_vm._s(_vm.question["questionAnswer"]))]),
          ]),
        ])
      : _vm._e(),
    !_vm.choosed && _vm.trainning
      ? _c(
          "div",
          { staticClass: "confirm-selection-main" },
          [
            _c(
              "van-button",
              { attrs: { type: "primary" }, on: { click: _vm.showAnswer } },
              [_vm._v(" 查看答案 ")]
            ),
          ],
          1
        )
      : _vm._e(),
    false
      ? _c(
          "div",
          { staticClass: "confirm-selection-main" },
          [
            _c(
              "van-button",
              {
                attrs: { type: "primary", block: "" },
                on: { click: _vm.submitChoose },
              },
              [_vm._v(" 确认答案 ")]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }