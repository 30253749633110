<template>
  <div class="subject-main">
    <div class="subject-title">
      <span class="subject-tag">简答题</span>
      <span>{{ questionIndex + 1 }}、</span>
      <span>{{ question['question'] }}</span>
    </div>
    <div
      class="subject-answer"
      v-if="!trainning"
    >
      <van-field
        @blur="fieldInput"
        v-model="chooseKey"
        rows="5"
        autosize
        type="textarea"
        maxlength="2000"
        placeholder="请输入答案"
        show-word-limit
      />
    </div>
    <div
      class="analysis-main"
      v-if="choosed"
    >
      <div class="analysis-content">
        <div class="analysis-content-title">
          题目答案
        </div>
        <div>{{ question['questionAnswer'] }}</div>
      </div>
    </div>
    <div
      class="confirm-selection-main"
      v-if="!choosed && trainning"
    >
      <van-button
        type="primary"
        @click="showAnswer"
      >
        查看答案
      </van-button>
    </div>
    <div
      class="confirm-selection-main"
      v-if="false"
    >
      <van-button
        type="primary"
        block
        @click="submitChoose"
      >
        确认答案
      </van-button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MultipleChoiceQuestion',
  components: {},
  props: {
    questionIndex: {
      type: Number,
      default: 0,
    },
    trainning: {
      Boolean,
      default: false,
    },
    question: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
      choosed: false,
      chooseKey: null,
    }
  },
  computed: {},
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  methods: {
    showAnswer() {
      this.choosed = true
      this.$emit('choose', this.chooseKey, this.questionIndex, this.question)

    },
    submitChoose() {
      this.choosed = true
      this.$emit('choose', this.chooseKey, this.questionIndex, this.question)
    },
    fieldInput() {
      this.$emit('choose', this.chooseKey, this.questionIndex, this.question)
    },
  },
  beforeDestroy() {},
  destroyed() {},
}
</script>
<style scoped lang="less" type="text/less">
.subject-main {
  font-size: 15px;
  color: black;
  height: 100%;
  padding: 10px 20px;
  background-color: #f5f5f5;
  line-height: 25px;
  .subject-title {
    .subject-tag {
      color: white;
      padding: 3px 7px;
      background-color: #409eff;
      border-radius: 5px;
      margin-right: 10px;
    }
  }
  .subject-answer {
    margin-top: 10px;
  }
  .analysis-main {
    .correct-answer {
      padding: 5px 10px;
      background-color: rgba(144, 144, 173, 0.3);
      .correct-answer-title {
        font-weight: bold;
      }
      .correct-answer-key {
        color: #409eff;
        margin-right: 10px;
      }
      .choosed-key {
        color: #f56c6c;
      }
    }
    .analysis-content {
      margin-top: 10px;
      border-top: 1px solid #646d67;
      .analysis-content-title {
        font-weight: bold;
      }
    }
  }
  .confirm-selection-main {
    margin: 20px 0;
    // position: absolute;
    // bottom: 30px;
    // left: 20px;
    // right: 20px;
  }
}
</style>
